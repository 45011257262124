<div #uwrcContainer class="contentContainer uwrcContainer textColor">
	<h1 class="mat-display-1">UW Richland Concerts</h1>

	<p>
		Starting in the winter of 2014 I began recording, mixing, and mastering the University of Wisconsin, Richland band &amp; choir concerts. This has been a delightful and fulfilling process for me. Professor Tobin Shucha has greatly facilitated these recordings and has become a good friend in the process. I have met many fine students and musicians and heard a lot of good and unusual music.
	</p>
	<p>
		TECH STUFF: The recording setup has settled into four overhead mic's hung from stage light bars and two front mic's - all condensors. This has allowed for a somewhat tolerant set of tracks and the ability to yield a decent stereo mix. The mics feed a Tascam US-1641 16 channel USB interface into my notebook with a SSD hard drive. All recording is done dry and the US-1641 pre-amps are quiet enough to allow decent headroom. Any compression, eq, reverb are done during mixing and mastering using 'Reaper' as a DAW. </p><p _ngcontent-c15=""> THE CONCERTS: Below you can download any of the recorded concerts. There will be two zip files for each concert: one for the band and one for the choir, and perhaps some extras plus programs starting in 2015. All the tracks are in MP3 format 256K, constant bit-rate. File sizes range from around 20Mb to 60Mb.
	</p>

  <ng-scrollbar #concertListSB class="concertListScrollbarContainer">
    <div class="concertListContainer">
      <mat-grid-list [cols]="columns" gutterSize="20px" rowHeight="110px">
    		<mat-grid-tile *ngFor="let c of data.concertList">
    			<div class="tileContent">
    				<div class="concertLinkHeader">{{c.title}}</div>
    				<div class="concertLinkLinks">
    					<a class="textColor"  *ngFor="let l of c.downloads" download [href]="l.link">{{l.tag}}</a>
    				</div>
    			</div>
    		</mat-grid-tile>
    	</mat-grid-list>
    </div>
  </ng-scrollbar>
</div>

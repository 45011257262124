<!-- loading sound samples msg -->
<div class="loadingSamples" *ngIf="isLoadingSamples">
  <h2>Loading Sound Samples...</h2>
  <mat-spinner color="primary" diameter="40"></mat-spinner>
</div>

<div class="controls">
  <!-- choose the instrument -->
  <div class="selInstrument">
    <mat-form-field floatLabel="'always'" class="selInstrument">
      <mat-select placeholder="Select an instrument..."
              disableOptionCentering
              [(ngModel)] = "data.currentInstrument"
              (selectionChange) = "updateChord()"
              #selInstrument aria-label="select an instrument">
        <mat-option *ngFor="let instrument of data.instruments"  [value]="instrument" [ngClass]="{'optionDivider': instrument.divider}">
          {{instrument.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- choose a scale -->
  <div class="selChord">
    <mat-form-field floatLabel="'always'">
      <mat-select placeholder="Select a chord..."
              disableOptionCentering
              [(ngModel)] = "data.currentChord"
              (selectionChange) = "updateChord()"
              #selScale aria-label="select a chord">
        <mat-option *ngFor="let chord of data.chords"  [value]="chord" [ngClass]="{'optionDivider': chord.divider}">
          {{chord.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- choose a key -->
  <div class="selKey">
    <mat-form-field floatLabel="'always'">
      <mat-select placeholder="Select Root Note..."
              disableOptionCentering
              [(ngModel)] = "data.currentKey"
              (selectionChange) = "updateChord()"
              #selKey aria-label="select a root note">
        <mat-option *ngFor="let key of data.keyNoteValues"  [value]="key">{{key.key}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- notes or intervals  -->
  <div class="notesOrIntervals">
    Notes
    <mat-slide-toggle
          color="primary"
          [(ngModel)]="data.fretboardIntervalFlag"
          (change)="updateChord()"
          aria-label="toggle notes or intervals">
    </mat-slide-toggle>
    Intervals
  </div>

  <!-- play -->
  <div class="level">
    <button class="levelButton" mat-icon-button color="primary">
      <i class="material-icons">volume_up</i>
    </button>
    <mat-slider color="primary" matTooltip="Set playback level..." min="0" max="2" step="0.01" [(value)]="audioLevel" aria-label="adjust playback level"></mat-slider>
    <button class="playButton" mat-icon-button color="primary" matTooltip="Play..." (click)="playChord()">
      <i class="material-icons" aria-label="play the chord">play_arrow</i>
    </button>
  </div>

  <!-- arpeggios or stringsets view -->
  <button mat-raised-button color="primary"
        matTooltip="Arpeggio view..."
        (click)="data.componentFlag = 'arpeggios'; data.showHelp = false"
        aria-label="show print arpeggios page">
    Arpeggios
  </button>
  <button mat-raised-button color="primary"
        matTooltip="Stringset view..."
        (click)="data.componentFlag = 'print'; data.showHelp = false"
        aria-label="show print stringsets page">
    String Sets
  </button>

  <!-- help -->
  <button mat-icon-button color="primary"
        matTooltip="Help for chords..."
        (click)="data.showHelp = true"
        aria-label="show help for chords">
    <i class="material-icons">help_outline</i>
  </button>
</div>

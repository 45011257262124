import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import {cloneDeep} from 'lodash';
const copyObj = cloneDeep;

class DataObj {
  notes: any;
}

@Injectable({providedIn: 'root'}) export class GlobalDataService {
  componentFlag = 'show';
  concertDlEndpoint;
  concertList;
  notes;
  samples;
  instruments;
  scales;
  keys;
  keyNoteValues;
  reach;
  chords;
  currentChord;
  currentInstrument;
  currentScale;
  currentRootNote;
  currentKey;
  fretboardMarkerArray = [];
  fretboardNoteArray = [];
  fretboardIntervalFlag = true;
  currentDisplayScale;
  dataLoaded = false;
  showHelp = false;

  constructor(private http: HttpClient) {}

  loadConfigData = async () => {
    await this.http.get('assets/conf/app.json', {responseType: 'json'}).toPromise().then(
      (d: any) => {
        this.concertDlEndpoint = copyObj(d.concertDlEndpoint);
        this.samples = copyObj(d.samples);
        this.keyNoteValues = copyObj(d.keyNoteValues);
        this.currentRootNote = this.keyNoteValues[6].key;
        this.currentKey = this.keyNoteValues[6];
      },
      e => {
        console.log('ERROR FETCHING CONFIG: ', e.error);
      }
    );
    await this.http.get('assets/conf/chords.json', {responseType: 'json'}).toPromise().then(
      (d: any) => {
        this.reach = d.reach;
        this.chords = copyObj(d.chords);
        this.currentChord = this.chords[0];
      },
      e => {
        console.log('ERROR FETCHING CONFIG: ', e.error);
      }
    );
    await this.http.get('assets/conf/instruments.json', {responseType: 'json'}).toPromise().then(
      (d: any) => {
        this.instruments = copyObj(d.instruments);
        this.currentInstrument = this.instruments[0];
      },
      e => {
        console.log('ERROR FETCHING CONFIG: ', e.error);
      }
    );
    await this.http.get('assets/conf/notes.json', {responseType: 'json'}).toPromise().then(
      (d: any) => {
        this.notes = copyObj(d.notes);
      },
      e => {
        console.log('ERROR FETCHING CONFIG: ', e.error);
      }
    );
    await this.http.get('assets/conf/scales.json', {responseType: 'json'}).toPromise().then(
      (d: any) => {
        this.scales = copyObj(d.scales);
        this.currentScale = this.scales[0];
      },
      e => {
        console.log('ERROR FETCHING CONFIG: ', e.error);
      }
    );
    await this.http.get('assets/conf/uwrc.json', {responseType: 'json'}).toPromise().then(
      (d: any) => {
        this.concertList = copyObj(d.concertList);
        this.dataLoaded = true;
      },
      e => {
        console.log('ERROR FETCHING CONFIG: ', e.error);
      }
    );
  }
}

// flatGlyph ♭, sharpGlyph ♯, doubleflatGlyph 𝄫, doubleSharpGlyph 𝄪

import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '../../providers/global-data.service';

@Component({
  selector: 'app-scale-detail-bar',
  templateUrl: './scale-detail-bar.component.html',
  styleUrls: ['./scale-detail-bar.component.styl']
})
export class ScaleDetailBarComponent implements OnInit {

  constructor(public data: GlobalDataService) { }

  ngOnInit() {
  }

}

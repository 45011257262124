<div class="wrapper mat-typography mat-app-background" [ngSwitch]="pageSelection">

    <!-- main menu -->
    <div class="mainMenuContainer noPrint" *ngIf="showMenu">
      <button mat-button class="menuButton" (click)="togglePage('about')">About</button>
      <button mat-button class="menuButton" (click)="togglePage('guitars')">Guitars</button>
      <button mat-button class="menuButton" (click)="togglePage('music')">Music</button>
      <mat-divider class="menuDivider"></mat-divider>
      <button mat-button class="menuButton" (click)="togglePage('chord-tool')">Chord Tool</button>
      <button mat-button class="menuButton" (click)="togglePage('scale-tool')">Scale Tool</button>
      <!-- <mat-divider class="menuDivider"></mat-divider> -->
      <!-- <button mat-button class="menuButton" (click)="togglePage('uwrc')">UWRC</button> -->
      <!-- <div class="subMenuButton" (mouseover)="showUwrcMenu = true">UWRC</div> -->
      <!-- <div class="uwrcMenuContainer noPrint" *ngIf="showUwrcMenu"> -->
        <!-- <button mat-button class="menuButton" (click)="togglePage('uwrc')">Concert Archives</button> -->
        <!-- <button mat-button class="menuButton" (click)="togglePage('uwrctools')">Performance Aids</button> -->
      <!-- </div> -->
      <mat-divider class="menuDivider"></mat-divider>
      <div class="emailLink" matTooltip="Send an email...">
				<a href="mailTo: cliffsmith53581@gmail.com">
					<mat-icon >mail_outline</mat-icon>
				</a>
			</div>
      <button mat-button class="menuButton" matTooltip="Home..." (click)="togglePage('home')">
        <mat-icon class="textColor">home</mat-icon>
      </button>
    </div>

    <!-- <button class="mainMenuButton" mat-icon-button (click)="toggleMainMenu()"> -->
    <button class="mainMenuButton noPrint" mat-icon-button (click)="toggleMainMenu()">
      <mat-icon>menu</mat-icon>
    </button>

    <!-- content area - components switched by menu, no routing -->
    <app-home *ngSwitchCase="'home'"></app-home>
    <app-about *ngSwitchCase="'about'"></app-about>
    <app-guitars *ngSwitchCase="'guitars'"></app-guitars>
    <app-music *ngSwitchCase="'music'"></app-music>
    <app-chord-tool *ngSwitchCase="'chord-tool'"></app-chord-tool>
    <app-scale-tool *ngSwitchCase="'scale-tool'"></app-scale-tool>
    <app-uwrc *ngSwitchCase="'uwrc'"></app-uwrc>
    <app-uwrctools *ngSwitchCase="'uwrctools'"></app-uwrctools>
</div>

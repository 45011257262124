import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// -- these follow the material.angular.io component list --

// -- form controls --
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

// -- navigation --
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';

// -- layout --
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatListModule} from '@angular/material/list';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTreeModule} from '@angular/material/tree';

// -- buttons & indicators --
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatBadgeModule} from '@angular/material/badge';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';

// -- popups & modals --
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';

// -- data table --
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';

// -- CDK --
import {A11yModule} from '@angular/cdk/a11y';
import {LayoutModule} from '@angular/cdk/layout';
import {OverlayModule} from '@angular/cdk/overlay';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';

@NgModule({
  declarations: [],
  // imports: [
  //   CommonModule,
  //   MatAutocompleteModule,
  //   MatCheckboxModule,
  //   MatDatepickerModule,
  //   MatFormFieldModule,
  //   MatInputModule,
  //   MatRadioModule,
  //   MatSelectModule,
  //   MatSliderModule,
  //   MatSlideToggleModule,
  //
  //   MatMenuModule,
  //   MatSidenavModule,
  //   MatToolbarModule,
  //
  //   MatCardModule,
  //   MatDividerModule,
  //   MatExpansionModule,
  //   MatGridListModule,
  //   MatListModule,
  //   MatStepperModule,
  //   MatTabsModule,
  //   MatTreeModule,
  //
  //   MatButtonModule,
  //   MatButtonToggleModule,
  //   MatBadgeModule,
  //   MatChipsModule,
  //   MatIconModule,
  //   MatProgressSpinnerModule,
  //   MatProgressBarModule,
  //
  //   MatBottomSheetModule,
  //   MatDialogModule,
  //   MatSnackBarModule,
  //   MatTooltipModule,
  //
  //   MatPaginatorModule,
  //   MatSortModule,
  //   MatTableModule,
  //
  //   A11yModule,
  //   LayoutModule,
  //   OverlayModule,
  //   CdkStepperModule,
  //   CdkTableModule,
  //   CdkTreeModule
  // ],
  exports: [
    CommonModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,

    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,

    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatListModule,
    MatStepperModule,
    MatTabsModule,
    MatTreeModule,

    MatButtonModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,

    MatBottomSheetModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,

    MatPaginatorModule,
    MatSortModule,
    MatTableModule,

    A11yModule,
    LayoutModule,
    OverlayModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule
  ]
})
export class MaterialModule {}

import { Component, AfterViewInit, NgZone } from '@angular/core';
import { GlobalDataService } from '../../providers/global-data.service';

@Component({
  selector: 'app-scale-controls-bar',
  templateUrl: './scale-controls-bar.component.html',
  styleUrls: ['./scale-controls-bar.component.styl']
})
export class ScaleControlsBarComponent implements AfterViewInit {
  isLoadingSamples = false;
  currentScaleSamples = ['A3.mp3', 'C4.mp3', 'E4.mp3'];
  audioLevel = 0.35;

  constructor(public data: GlobalDataService, public zone: NgZone) { }

  ngAfterViewInit() {
    this.updateScale();
  }

  // -- component is initialized --

  // -- build the scale arrays --
  updateScale() {
    this.data.fretboardNoteArray = [];

    // -- build iteration array --
    const iterator = Array.from(Array(this.data.currentInstrument.fretCount + 1).keys());

    // -- build marker array --
    this.data.fretboardMarkerArray = [];
    for (const m of iterator) {
      if ( m > 0 ) {
        this.data.fretboardMarkerArray.push(
          (m === 3 || m === 5 || m === 7 || m === 9 || m === 12 ||
            m === 15 || m === 17 || m === 19 || m === 21 || m === 24) ? m.toString() : '');
      }
    }

    // -- get scale tones & root note --
    const notes = this.data.notes;
    const keyIdx = this.data.keyNoteValues.findIndex((r) => r.key === this.data.currentKey.key);
    const keyNotesArray = this.data.keyNoteValues[keyIdx].noteValues;
    const offset = this.data.currentScale.keyOffset;
    this.data.currentRootNote = keyNotesArray[offset];

    // -- iterate over strings & build each string note arrays --
    for (let strIdx = 0; strIdx < this.data.currentInstrument.stringCount; strIdx++) {
      const tuning = this.data.currentInstrument.tuning[strIdx];
      let nutNoteIdx;
      if (this.data.currentInstrument.banjo && strIdx === this.data.currentInstrument.stringCount - 1) {
        nutNoteIdx = notes.findIndex((n) => n.flatName === tuning || n.sharpName === tuning) - 5;
      } else {
        nutNoteIdx = notes.findIndex((n) => n.flatName === tuning || n.sharpName === tuning);
      }
      let nutNoteOffset = keyNotesArray.findIndex((r) => {
          return (r === notes[nutNoteIdx].flatName.slice(0, -1)) ||
          (r === notes[nutNoteIdx].sharpName.slice(0, -1));
        });
      nutNoteOffset = (nutNoteOffset + 12 - offset) % 12;
      this.data.fretboardNoteArray.push(new Array());

      for (const idx of iterator) {
        const noteObj: any = {};
        const noteIdx = this.data.currentScale.halfSteps.findIndex((r) => {
          if (this.data.currentInstrument.banjo && strIdx === this.data.currentInstrument.stringCount - 1) {
            if ( idx > 4) {
              return (r === (idx + nutNoteOffset) % 12);
            } else {
              return false;
            }
          } else {
            return (r === (idx + nutNoteOffset) % 12);
          }
        });

        if (noteIdx >= 0) {
          noteObj.note = keyIdx > 6 ?
            notes[idx + nutNoteIdx].sharpName.slice(0, -1) :
            notes[idx + nutNoteIdx].flatName.slice(0, -1);
          noteObj.interval = this.data.currentScale.intervals[noteIdx];
          noteObj.class = noteIdx === 0 ? 'rootNote' : '';
          if (noteObj.interval.includes('down')) {
            noteObj.interval = noteObj.interval.substring(0, noteObj.interval.indexOf(' '));
            noteObj.class = 'downNote';
          }
          if (noteObj.interval.includes('up')) {
            noteObj.interval = noteObj.interval.substring(0, noteObj.interval.indexOf(' '));
            noteObj.class = 'upNote';
          }
          if (this.data.currentInstrument.banjo && idx === 5 && strIdx === this.data.currentInstrument.stringCount - 1) {
              noteObj.class = noteObj.class ? noteObj.class + ' banjoNut' : 'banjoNut';
          }
          noteObj.visible = true;
          noteObj.display = this.data.fretboardIntervalFlag ? noteObj.interval : noteObj.note;
        } else {
          noteObj.display = '';
          noteObj.note = '';
          noteObj.interval = '';
          noteObj.class = '';
          if (this.data.currentInstrument.banjo && idx <= 5 &&
                strIdx === this.data.currentInstrument.stringCount - 1) {
            if (idx < 5) {
              noteObj.class = 'banjo';
            } else {
              noteObj.class = noteObj.class ? noteObj.class + ' banjoNut' : 'banjoNut';
            }
          }
          noteObj.visible = false;
        }

        this.data.fretboardNoteArray[strIdx].push(noteObj);
      }
    }

    // -- build samples array --
    this.currentScaleSamples = [];
    const upSamples = [];
    const downSamples = [];
    const sampleRootIdx = this.data.samples.findIndex(
      (r) => this.data.currentRootNote === r.sharpName || this.data.currentRootNote === r.flatName);
    for (const [idx, halfsteps] of this.data.currentScale.halfSteps.entries()) {
      if (!this.data.currentScale.intervals[idx].includes('down')) {
        upSamples.push(this.data.samples[sampleRootIdx + halfsteps].sample);
      }
      if (!this.data.currentScale.intervals[idx].includes('up')) {
        downSamples.push(this.data.samples[sampleRootIdx + halfsteps].sample);
      }
    }
    upSamples.push(this.data.samples[sampleRootIdx + 12].sample);
    downSamples.push(this.data.samples[sampleRootIdx + 12].sample);
    downSamples.reverse();
    this.currentScaleSamples = upSamples.concat(downSamples);
  }


  // -- play the scale --
  playScale = async () => {
    this.isLoadingSamples = true;
    this.zone.run(() => null);

    // instantiate audio context
    const audioCtx = new (window as any).AudioContext();
    const scaleSrc = [];

    // volume control
    const gainNode = audioCtx.createGain();
    const volumeControl = document.querySelector('#volume');
    gainNode.gain.value = this.audioLevel;
    gainNode.connect(audioCtx.destination);


    for (const sample of this.currentScaleSamples) {
      await window.fetch('../assets/samples/' + sample)
        .then(resp => resp.arrayBuffer())
        .then(aBuf => audioCtx.decodeAudioData(aBuf))
        .then(audioBuf => {
          const src = audioCtx.createBufferSource();
          src.buffer = audioBuf;
          scaleSrc.push(src);
      });
    }

    this.isLoadingSamples = false;
    this.zone.run(() => null);

    // release resources when finished
    scaleSrc[scaleSrc.length - 1].onended = () => audioCtx.close();

    // play one note at a time
    for (let n = 0; n < scaleSrc.length; n++) {
      setTimeout( () => {
        scaleSrc[n].connect(gainNode);
        scaleSrc[n].start();
      }, 600 * n);
    }
  }
}

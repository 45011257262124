<div class="scaleDetails">
  <span class="bold">
    Scale:  <span class="normal">{{data.currentScale.name}}</span>
  </span>
  <span class="bold">
    Key: <span class="normal">{{data.currentKey.key}}</span>
  </span>
  <span class="bold">
    Root Note: <span class="normal">{{data.currentRootNote}}</span>
  </span>
  <span class="bold">
    Intervals: <span class="normal">{{data.currentScale.intervals.join(' - ')}} - R</span>
  </span>
  <span class="bold">
    Instrument: <span class="normal">{{data.currentInstrument.name}}</span>
  </span>
</div>

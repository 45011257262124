<div class="help helpPanel" *ngIf="data.showHelp">
  <!-- close -->
  <button mat-icon-button class="closeButton"
          matTooltip="close..."
          (click)="data.showHelp = false"
          aria-label="close the help window">
    <i class="material-icons">close</i>
  </button>

  <h1 class="mat-text-2">Scale Library Help...</h1>
  <p>
    The Scale print page starts out with all the notes of the selected scale. You will see either actual note names or the intervals of the scale depending on the 'notes /interval' toggle.
  </p>
  <p>
    To design a long scale: start by experimenting with various fingerings, when satisfied turn off the other notes by clicking on them - they will be grayed out and can be turned back on by clicking again.
  </p>
  <p>
    Set the printer page settings to 'landscape' or the fretboard won't fit on a page
  </p>
  <p>
    After printing, fiddle with the notes to design another long scale and print it. This way you can build a library of different scales for your favorite keys.
  </p>
</div>

<div class="controls noPrint">
  <!-- print -->
  <button mat-icon-button color="primary" matTooltip="Print..." aria-label="print the scale">
    <i class="material-icons" (click)="printScale()">print</i>
  </button>

  <!-- cancel -->
  <button mat-icon-button color="primary" matTooltip="Back..." aria-label="back to the scale page">
    <i class="material-icons"
          (click)="data.componentFlag = 'show'; data.showHelp = false">keyboard_backspace</i>
  </button>

  <!-- help -->
  <button mat-icon-button color="primary" matTooltip="Help for printing scales..."
          (click)="data.showHelp = true" aria-label="help for printing scales">
    <i class="material-icons">help_outline</i>
  </button>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { FretboardModule } from '../fretboard/fretboard.module';

import { ChordToolComponent } from './chord-tool.component';
import { PrintControlsBarComponent } from './print-controls-bar/print-controls-bar.component';
import { ShowControlsBarComponent } from './show-controls-bar/show-controls-bar.component';
import { ChordDetailBarComponent } from './chord-detail-bar/chord-detail-bar.component';
import { ShowChordsComponent } from './show-chords/show-chords.component';
import { PrintChordsComponent } from './print-chords/print-chords.component';
import { PrintArpeggiosComponent } from './print-arpeggios/print-arpeggios.component';
import { ChordControlsBarComponent } from './chord-controls-bar/chord-controls-bar.component';
import { ShowChordsHelpComponent } from './show-chords/show-chords-help/show-chords-help.component';
import { PrintChordsHelpComponent } from './print-chords/print-chords-help/print-chords-help.component';
import { PrintArpeggiosHelpComponent } from './print-arpeggios/print-arpeggios-help/print-arpeggios-help.component';

@NgModule({
  declarations: [
    ChordToolComponent,
    PrintControlsBarComponent,
    ShowControlsBarComponent,
    ChordDetailBarComponent,
    ShowChordsComponent,
    PrintChordsComponent,
    PrintArpeggiosComponent,
    ChordControlsBarComponent,
    ShowChordsHelpComponent,
    PrintChordsHelpComponent,
    PrintArpeggiosHelpComponent
  ],
  imports: [
    FormsModule,
    MaterialModule,
    FretboardModule,
    NgScrollbarModule,
    CommonModule
  ],
  exports: [
    ChordToolComponent
  ]
})
export class ChordToolModule { }

<!-- help for chord tool page -->
<div class="help helpPanel" *ngIf="data.showHelp">
  <!-- close -->
  <button mat-icon-button class="closeButton" matTooltip="close..." (click)="data.showHelp = false">
    <i class="material-icons">close</i>
  </button>

  <h1 class="mat-text-2">Chord Tool Help...</h1>
  <p>
    The Chord Tool will show all the notes of any selected chord. You will see either actual note names or the intervals of the chord.
  </p>
  <p>
    Choose an instrument to set the tunings for each string.
  <p>
    Choose a root note from the root note list and then choose a chord from the chord list: the chord list is loosely grouped by MAJOR, DOMINANT, MINOR, DIMINISHED & AUGMENTED, and OTHER EXTENDED CHORDS.
  </p>
  <p>
    Use the toggle to switch between note names or intervals.
  </p>
  <p>
    You can play the chord using the audio controls.
  </p>
  <p>
    The 'Arpeggios' button displays a page where you can selectively turn off notes to build a library of various arpeggios.
  </p>
  <p>
    The 'StringSet' Button displays a page where you can build a library of fretboard chord diagrams for  various stringsets based on the current chord.
  </p>
</div>

<div class="scaleToolContainer">
  <h1 class="mat-text-2">Scale Tool</h1>

  <app-scale-controls-bar></app-scale-controls-bar>
  <app-scale-detail-bar></app-scale-detail-bar>

  <div class="noteKey">
    Root Note: <div class="rootNote">R</div>
    Up Note: <div class="upNote">U</div>
    Down Note: <div class="downNote">D</div>
  </div>

  <app-fretboard></app-fretboard>
  <app-show-scale-help *ngIf="data.showHelp"></app-show-scale-help>
</div>

<div class="printScaleContainer">
  <h1 class="mat-text-2">Scale Library</h1>
  <app-print-controls-bar></app-print-controls-bar>
  <app-scale-detail-bar></app-scale-detail-bar>

  <app-fretboard></app-fretboard>

  <div class="centered noPrint">(for long scales: click on notes to hide/show from the final print...)</div>

  <app-print-scale-help *ngIf="data.showHelp"></app-print-scale-help>
</div>

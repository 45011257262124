import { Component } from '@angular/core';
import { GlobalDataService } from '../../../providers/global-data.service';

@Component({
  selector: 'app-print-scale-help',
  templateUrl: './print-scale-help.component.html',
  styleUrls: ['./print-scale-help.component.styl']
})
export class PrintScaleHelpComponent {

  constructor(public data: GlobalDataService) { }

}

<div class="controls noPrint">
  <!-- print -->
  <button mat-icon-button color="primary" matTooltip="Print..." aria-label="print">
    <i class="material-icons" (click)="print()">print</i>
  </button>

  <!-- cancel -->
  <button mat-icon-button color="primary" matTooltip="Back..." aria-label="back to chords page">
    <i class="material-icons"
          (click)="data.componentFlag = 'show'; data.showHelp = false">keyboard_backspace</i>
  </button>

  <!-- help -->
  <button mat-icon-button color="primary" matTooltip="Help for stringsets / arpeggios..." (click)="data.showHelp = true" aria-label="help for stringset diagrams">
    <i class="material-icons">help_outline</i>
  </button>
</div>

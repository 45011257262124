import { Component } from '@angular/core';
import { GlobalDataService } from '../../providers/global-data.service';

@Component({
  selector: 'app-show-chords',
  templateUrl: './show-chords.component.html',
  styleUrls: ['./show-chords.component.styl']
})
export class ShowChordsComponent {

  constructor(public data: GlobalDataService) { }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';

import { FretboardModule } from '../fretboard/fretboard.module';
import { ScaleToolComponent } from './scale-tool.component';
import { ScaleDetailBarComponent } from './scale-detail-bar/scale-detail-bar.component';
import { ShowScaleComponent } from './show-scale/show-scale.component';
import { PrintScaleComponent } from './print-scale/print-scale.component';
import { ScaleControlsBarComponent } from './scale-controls-bar/scale-controls-bar.component';
import { PrintControlsBarComponent } from './print-controls-bar/print-controls-bar.component';
import { ShowScaleHelpComponent } from './show-scale/show-scale-help/show-scale-help.component';
import { PrintScaleHelpComponent } from './print-scale/print-scale-help/print-scale-help.component';

@NgModule({
  declarations: [
    ScaleToolComponent,
    ScaleDetailBarComponent,
    ShowScaleComponent,
    PrintScaleComponent,
    ScaleControlsBarComponent,
    PrintControlsBarComponent,
    ShowScaleHelpComponent,
    PrintScaleHelpComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    MaterialModule,
    FretboardModule
  ],
  exports: [
    ScaleToolComponent
  ]
})
export class ScaleToolModule { }

<div class="loginDialog brick-theme-light" (keyup.enter)="login()">
  <div mat-dialog-title align="center">Please Login...</div>
  <div mat-dialog-content>
    <mat-form-field floatLabel="always">
      <mat-placeholder>Login name...</mat-placeholder>
      <input matInput type="text" [(ngModel)]="userLogin">
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <mat-placeholder>Password...</mat-placeholder>
      <input matInput [type]="showPassword ? 'text' : 'password'" [(ngModel)]="password">
    </mat-form-field>
    <button mat-button color="primary" (click)="toggleShowPassword()">Show</button>
  </div>
  <div mat-dialog-actions align="center">
  	<button mat-raised-button color="accent" (click)="login()">Login</button>
		<button mat-raised-button color="accent" (click)="logout()">Logout</button>
  	<button mat-raised-button color="accent" (click)="cancel()">Cancel</button>
  </div>
</div>

import { Component, NgZone, AfterViewInit } from '@angular/core';
import { GlobalDataService } from '../../providers/global-data.service';

@Component({
  selector: 'app-chord-controls-bar',
  templateUrl: './chord-controls-bar.component.html',
  styleUrls: ['./chord-controls-bar.component.styl']
})
export class ChordControlsBarComponent implements AfterViewInit {
  isLoadingSamples = false;
  currentChordSamples = ['A3.mp3', 'C4.mp3', 'E4.mp3'];
  audioLevel = 0.5;

  constructor(public data: GlobalDataService, public zone: NgZone) { }

  ngAfterViewInit() {
    this.updateChord();
  }

  // -- component is initialized --

  // -- build the chord arrays --
  updateChord() {
    this.data.fretboardNoteArray = [];

    // -- build iteration array --
    const iterator = Array.from(Array(this.data.currentInstrument.fretCount + 1).keys());

    // -- build marker array --
    this.data.fretboardMarkerArray = [];
    for (const m of iterator) {
      if ( m > 0 ) {
        this.data.fretboardMarkerArray.push(
          (m === 3 || m === 5 || m === 7 || m === 9 || m === 12 ||
            m === 15 || m === 17 || m === 19 || m === 21 || m === 24) ? m.toString() : '');
      }
    }

    // -- get chord tones & root note --
    const notes = this.data.notes;
    const keyIdx = this.data.keyNoteValues.findIndex((r) => r.key === this.data.currentKey.key);
    const keyNotesArray =
        this.data.keyNoteValues[keyIdx].noteValues.concat(this.data.keyNoteValues[keyIdx].noteValues);
        // this.data.keyNoteValues[keyIdx].noteValues;
    this.data.currentRootNote = keyNotesArray[0];
  //
  //   // -- iterate over strings & build each string note arrays --
    for (let strIdx = 0; strIdx < this.data.currentInstrument.stringCount; strIdx++) {
      const tuning = this.data.currentInstrument.tuning[strIdx];
      let nutNoteIdx;
      if (this.data.currentInstrument.banjo && strIdx === this.data.currentInstrument.stringCount - 1) {
        nutNoteIdx = notes.findIndex((n) => n.flatName === tuning || n.sharpName === tuning) - 5;
      } else {
        nutNoteIdx = notes.findIndex((n) => n.flatName === tuning || n.sharpName === tuning);
      }
      const nutNoteOffset = keyNotesArray.findIndex((r) => {
          return (r === notes[nutNoteIdx].flatName.slice(0, -1)) ||
          (r === notes[nutNoteIdx].sharpName.slice(0, -1));
        });
      this.data.fretboardNoteArray.push(new Array());

      for (const idx of iterator) {
        const noteObj: any = {};
        const noteIdx = this.data.currentChord.halfSteps.findIndex((r) => {
          if (this.data.currentInstrument.banjo && strIdx === this.data.currentInstrument.stringCount - 1) {
            if ( idx > 4) {
              return (r === (idx + nutNoteOffset) % 24 || r === (idx + nutNoteOffset) % 12);
            } else {
              return false;
            }
          } else {
            if (r > 11) {
              return (r === (idx + nutNoteOffset) % 24 || r - 12 === (idx + nutNoteOffset) % 12);
            } else {
              return (r === (idx + nutNoteOffset) % 12);
            }
            // return r === ((idx + nutNoteOffset) > 11 ?
                  // (idx + nutNoteOffset - 12) % 12 : (idx + nutNoteOffset) % 12);
          }
        });

        if (noteIdx >= 0) {
          noteObj.note = keyIdx > 6 ?
            notes[idx + nutNoteIdx].sharpName.slice(0, -1) :
            notes[idx + nutNoteIdx].flatName.slice(0, -1);
          noteObj.interval = this.data.currentChord.intervals[noteIdx];
          noteObj.class = (noteIdx === 0 || noteIdx === 12) ? 'rootNote' : '';
          if (this.data.currentInstrument.banjo && idx === 5 &&
                strIdx === this.data.currentInstrument.stringCount - 1) {
            noteObj.class = noteObj.class ? noteObj.class + ' banjoNut' : 'banjoNut';
          }
          noteObj.visible = true;
          noteObj.display = this.data.fretboardIntervalFlag ? noteObj.interval : noteObj.note;
        } else {
          noteObj.display = '';
          noteObj.note = '';
          noteObj.interval = '';
          noteObj.class = '';
          if (this.data.currentInstrument.banjo && idx < 5 &&
                strIdx === this.data.currentInstrument.stringCount - 1) {
            noteObj.class = 'banjo';
          }
          noteObj.visible = false;
        }

        this.data.fretboardNoteArray[strIdx].push(noteObj);
      }
    }

    // -- build samples array --
    this.currentChordSamples = [];
    const sampleRootIdx = this.data.samples.findIndex(
      (r) => this.data.currentRootNote === r.sharpName || this.data.currentRootNote === r.flatName);
    for (const [idx, halfsteps] of this.data.currentChord.halfSteps.entries()) {
      this.currentChordSamples.push(this.data.samples[sampleRootIdx + halfsteps].sample);
    }
  }


  // -- play the chord --
  playChord = async () => {
    this.isLoadingSamples = true;
    this.zone.run(() => null);

    // instantiate audio context
    const audioCtx = new (window as any).AudioContext();
    const chordSrc = [];

    // volume control
    const gainNode = audioCtx.createGain();
    const volumeControl = document.querySelector('#volume');
    gainNode.gain.value = this.audioLevel;
    gainNode.connect(audioCtx.destination);

    for (const sample of this.currentChordSamples) {
      await window.fetch('../assets/samples/' + sample)
        .then(resp => resp.arrayBuffer())
        .then(aBuf => audioCtx.decodeAudioData(aBuf))
        .then(audioBuf => {
          const src = audioCtx.createBufferSource();
          src.buffer = audioBuf;
          chordSrc.push(src);
      });
    }

    this.isLoadingSamples = false;
    this.zone.run(() => null);

    // release resources when finished
    chordSrc[chordSrc.length - 1].onended = () => audioCtx.close();

    // play one note at a time
    for (let n = 0; n < chordSrc.length; n++) {
      setTimeout( () => {
        chordSrc[n].connect(gainNode);
        chordSrc[n].start();
      }, 200 * n);
    }
  }
}

<div class="help helpPanel" *ngIf="data.showHelp">
  <!-- close -->
  <button mat-icon-button class="closeButton"
          matTooltip="close..."
          (click)="data.showHelp = false"
          aria-label="close the help window">
    <i class="material-icons">close</i>
  </button>

  <h1 class="mat-text-2">Arpeggio Library Help...</h1>
  <p>
    The Arpeggios page starts out with all the notes of the selected chord. You will see either actual note names or the intervals of the chord depending on the 'notes /interval' toggle.
  </p>
  <p>
    To design an arpeggio: start by experimenting with various fingerings, when satisfied turn off the other notes by clicking on them - they will be grayed out and can be turned back on by clicking again.
  <p>
    Set the printer page settings to 'landscape' or the fretboard won't fit on a page
  </p>
  <p>
    After printing, fiddle with the notes to design another arpeggio and print it. This way you can build a library of different arpeggios for your favorite chords.
  </p>
</div>

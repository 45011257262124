import { Component, Output, EventEmitter } from '@angular/core';
import { GlobalDataService } from '../../providers/global-data.service';

@Component({
  selector: 'app-print-controls-bar',
  templateUrl: './print-controls-bar.component.html',
  styleUrls: ['./print-controls-bar.component.styl']
})
export class PrintControlsBarComponent {
  @Output() printChords = new EventEmitter();

  constructor(public data: GlobalDataService) { }


  print() {
    // window.onafterprint = () => console.log('onafterprint() fires...');
    // window.print();
    this.printChords.emit('printing');
  }
}

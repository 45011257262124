import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { MaterialModule } from './material/material.module';
import { LoginModule } from './login/login.module';

import { ScaleToolModule } from './scale-tool/scale-tool.module';
import { ChordToolModule } from './chord-tool/chord-tool.module';

import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { GuitarsComponent } from './guitars/guitars.component';
import { MusicComponent } from './music/music.component';
import { UwrcComponent } from './uwrc/uwrc.component';
import { HomeComponent } from './home/home.component';
import { UwrctoolsComponent } from './uwrctools/uwrctools.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    GuitarsComponent,
    MusicComponent,
    UwrcComponent,
    HomeComponent,
    UwrctoolsComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    NgScrollbarModule,
    MaterialModule,
    LoginModule,
    ScaleToolModule,
    ChordToolModule
  ],
  exports: [
    BrowserModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

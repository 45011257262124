<div class="chordDetails">
  <span class="bold">
    Chord:  <span class="normal">{{data.currentChord.name}}</span>
  </span>
  <span class="bold">
    Root Note: <span class="normal">{{data.currentKey.key}}</span>
  </span>
  <span class="bold">
    Intervals: <span class="normal">{{data.currentChord.intervals.join(' - ')}}</span>
  </span>
  <span class="bold">
    Instrument: <span class="normal">{{data.currentInstrument.name}}</span>
  </span>
</div>

import { Component } from '@angular/core';
import { GlobalDataService } from '../../../providers/global-data.service';

@Component({
  selector: 'app-show-scale-help',
  templateUrl: './show-scale-help.component.html',
  styleUrls: ['./show-scale-help.component.styl']
})
export class ShowScaleHelpComponent {

  constructor(public data: GlobalDataService) { }

}

import { Component } from '@angular/core';
import { GlobalDataService } from '../../providers/global-data.service';

@Component({
  selector: 'app-show-scale',
  templateUrl: './show-scale.component.html',
  styleUrls: ['./show-scale.component.styl']
})
export class ShowScaleComponent {

  constructor(public data: GlobalDataService) { }

}

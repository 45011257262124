import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '../providers/global-data.service';

@Component({
  selector: 'app-chord-tool',
  templateUrl: './chord-tool.component.html',
  // template: '<h1>Chord tool works...',
  styleUrls: ['./chord-tool.component.styl']
})
export class ChordToolComponent implements OnInit {

  constructor(public data: GlobalDataService) { }

  ngOnInit() {
    this.data.componentFlag = 'show';
  }

}

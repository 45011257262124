<!-- loading sound samples msg -->
<div class="loadingSamples" *ngIf="isLoadingSamples">
  <h2>Loading Sound Samples...</h2>
  <mat-spinner color="primary" diameter="40"></mat-spinner>
</div>

<div class="controls">
  <!-- choose the instrument -->
  <mat-form-field floatLabel="'always'" class="selInstrument">
    <mat-select placeholder="Select an instrument..."
            disableOptionCentering
            [(ngModel)] = "data.currentInstrument"
            (selectionChange) = "updateScale()"
            #selInstrument  aria-label="select an instrument">
      <mat-option *ngFor="let instrument of data.instruments"  [value]="instrument" [ngClass]="{'optionDivider': instrument.divider}">
        {{instrument.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- choose a scale -->
  <div class="selScale">
    <mat-form-field floatLabel="'always'">
      <mat-select placeholder="Select scale..."
              disableOptionCentering
              [(ngModel)] = "data.currentScale"
              (selectionChange) = "updateScale()"
              #selScale aria-label="select a scale">
        <mat-option *ngFor="let scale of data.scales"  [value]="scale" [ngClass]="{'optionDivider': scale.divider}">
          {{scale.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- choose a key -->
  <div class="selKey">
    <mat-form-field floatLabel="'always'" aria-label="select a key">
      <mat-select placeholder="Select key..."
              disableOptionCentering
              [(ngModel)] = "data.currentKey"
              (selectionChange) = "updateScale()"
              #selKey aria-label="select a key">
        <mat-option *ngFor="let key of data.keyNoteValues"  [value]="key">{{key.key}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- notes or intervals  -->
  <div class="notesOrIntervals">
    Notes
    <mat-slide-toggle
          color="primary"
          [(ngModel)]="data.fretboardIntervalFlag"
          (change)="updateScale()" aria-label="toggle notes or intervals">
    </mat-slide-toggle>
    Intervals
  </div>

  <!-- play -->
  <div class="level">
    <button class="levelButton" mat-icon-button color="primary">
      <i class="material-icons">volume_up</i>
    </button>
    <mat-slider color="primary" matTooltip="Set playback level..." min="0" max="2" step="0.01" [(value)]="audioLevel" aria-label="adjust playback level"></mat-slider>
    <button class="playButton" mat-icon-button color="primary"
        matTooltip="Play..." (click)="playScale()" aria-label="play the scale">
      <i class="material-icons">play_arrow</i>
    </button>
  </div>

  <!-- print -->
  <button mat-icon-button color="primary" matTooltip="Print..." aria-label="show print scales page">
    <i class="material-icons" (click)="data.componentFlag = 'print'; data.showHelp = false">print</i>
  </button>

  <!-- help -->
  <button
        mat-icon-button
        color="primary"
        matTooltip="Help for scales..."
        (click)="data.showHelp = true"
        aria-label="show help for scales">
    <i class="material-icons">help_outline</i>
  </button>
</div>

import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { GlobalDataService } from '../providers/global-data.service';
import { NgScrollbar } from 'ngx-scrollbar';


@Component({
  selector: 'app-uwrc',
  templateUrl: './uwrc.component.html',
  styleUrls: ['./uwrc.component.styl']
})
export class UwrcComponent implements OnInit, AfterViewInit {
  columns = 2;
  @ViewChild('concertListSB') concertListSBRef: NgScrollbar;

  constructor(public data: GlobalDataService) { }

  ngOnInit() {
    console.log('uwrc-ngOnInit() fires...');
  }

  ngAfterViewInit() {
    console.log('uwrc-ngAfterViewInit() fires...');
  }
}

<div class="contentContainer aboutContainer textColor">
  <h1 class="mat-display-1">Hi...! and Welcome.</h1>
  <p>
    My name is Cliff and music is one of my passions. This site is dedicated to that passion. I hope to document and share both my own work and that of others where I live.
  </p>
  <p>
    I both play and build guitars, mostly classical. I also record, mix, and master local music. I live in the driftless area of Wisconsin, US of A and this section of our state is blessed with a great many very fine musicians, both singers and instrumentalists. The styles range across classical, folk, old-timey, country, blue-grass, singer-songwriter, blues, rock, and even metal.
  </p>
  <p>
    I am an amateur musician but I do perform at open mic's and house concerts around our area as well as 'in the pit' for musical theatre. I also collaborate with other musicians, especially my friend Dave who plays the hammered dulcimer. We do mostly classical music, arranging pieces of JS Back, Granados, Satie, Ravel, Tchaikovsky, Bartok, Grieg, and others and you can hear us perform around the area.
  </p>
  <p>
    In this site you will find:
  </p>
  <div class="aboutListItem">
    <mat-icon>music_note</mat-icon>
    A scale tool and a chord tool for fretted instruments. The scale tool will generate fretboard fingerings for a number of different scales in keys from G♭ to F♯. The chord tool will generate chord shapes for various chords. Both tools include an array of selectable tunings.
  </div>
  <div class="aboutListItem">
    <mat-icon>music_note</mat-icon>
    A gallery of some of the instruments that I have built over the years: Guitars, dulcimers, basses.
  </div>
  <div class="aboutListItem">
    <mat-icon>music_note</mat-icon>
    Various recordings for download. These are in MP3 format.
  </div>
  <div class="aboutListItem">
    <mat-icon>music_note</mat-icon>
    Various scores in PDF format, solo works as well as duets. These include some original works and duet arrangements of classical pieces. The duets were written for guitar & hammered dulcimer but work equally well with violin, flute, or other non-transposing intruments. I am a Sibelius user and all these scores are built with that program.
  </div>
</div>

import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { GlobalDataService } from './providers/global-data.service';
import { LoginComponent } from './login/login.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl']
})
export class AppComponent implements OnInit {
  title = 'Studio';
  showMenu = false;
  showUwrcMenu = false;
  showMenuTimer;
  pageSelection = 'home';
  isLoggedIn = false;
  overlay;
  loginDialogRef;

  constructor(
    public dialog: MatDialog,
    public overlayContainer: OverlayContainer,
    public zone: NgZone,
    public data: GlobalDataService
  ) {}

  ngOnInit() {
    try {
      this.overlay = this.overlayContainer.getContainerElement();
      this.data.loadConfigData();
    } catch (err) {
      console.log('SOMETHING WENT WRONG FETCHING CONFIG DATA...');
      console.log('  ', JSON.stringify(err));
    }
  }

  toggleMainMenu() {
    this.showMenu = !this.showMenu;
    if (this.showMenu) {
      this.showMenuTimer = setTimeout(() => {
        this.showMenu = false;
        this.showUwrcMenu = false;
      }, 8000);
    }
  }

  togglePage(page) {
    this.showMenu = false;
    this.showUwrcMenu = false;
    clearTimeout(this.showMenuTimer);
    console.log('menu: ', page, ' logged in: ', this.isLoggedIn);
    if (page === 'uwrc' || page === 'uwrctools') {
      if (this.isLoggedIn) {
        this.pageSelection = page;
      } else {
        this.loginUser(page);
      }
    } else {
      // this.isLoggedIn = false;
      this.pageSelection = page;
    }
    // this.pageSelection = page;
  }

  loginUser(page) {
    if (this.isLoggedIn) {
      this.data.componentFlag = page;
    } else {
      this.loginDialogRef = this.dialog.open(LoginComponent);
      this.loginDialogRef.afterClosed().subscribe( result => {
        switch (result.action) {
          case 'logged-in':
            this.isLoggedIn = true;
            this.pageSelection = page;
            break;
          case 'logged-out':
            this.isLoggedIn = false;
            this.pageSelection = 'home';
            break;
          case 'cancel':
            break;
        }
        console.log('loginUser() returns: ', result.action);
      });
    }
  }
}

import { Component, AfterViewInit } from '@angular/core';
import { GlobalDataService } from '../providers/global-data.service';

@Component({
  selector: 'app-fretboard',
  templateUrl: './fretboard.component.html',
  styleUrls: ['./fretboard.component.styl']
})
export class FretboardComponent implements AfterViewInit {
  showFretboard = false;

  constructor(public data: GlobalDataService) { }

  ngAfterViewInit() {
    setTimeout(() => this.showFretboard = true);
  }

  toggleNote(ev) {
    if (this.data.componentFlag === 'print' || this.data.componentFlag === 'arpeggios') {
      if (ev.target.classList.contains('noteHide')) {
        ev.target.classList.remove('noteHide');
      } else {
        ev.target.classList.add('noteHide');
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guitars',
  templateUrl: './guitars.component.html',
  styleUrls: ['./guitars.component.styl']
})
export class GuitarsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

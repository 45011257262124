import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-controls-bar',
  templateUrl: './show-controls-bar.component.html',
  styleUrls: ['./show-controls-bar.component.styl']
})
export class ShowControlsBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

<div class="help helpPanel" *ngIf="data.showHelp">
  <!-- close -->
  <button mat-icon-button class="closeButton"
        matTooltip="close..."
        (click)="data.showHelp = false"
        aria-label="close the help window">
    <i class="material-icons">close</i>
  </button>

  <h1 class="mat-text-2">Chord Stringset Library Help...</h1>
  <p>
    The Stringset page displays fretboard chord diagrams for various stringsets for the notes of the selected chord. The note values are in intervals.
  </p>
  <p>
    The stringsets are: 4321, 5432, 6543, 5321, 6432. Seven string guitars add 7543 and 7432 stringsets. In addition a full stringset is also shown.
  </p>
  <p>
    The full stringset diagrams allow you to selectively pick barred or easily grabbed fingerings.
  </p>
  <p>
    The rules for building the diagrams are complex and based on a concept of 'preferred intervals' for chords of more than three notes. A diagram must include the first three preferences once after which any combination of the other preferences will be added (eg: A(6/9) - preferences are [9, 6, 3, R, 5]. So each diagram will contain the 6, the 9, the 3, and any of the remaining intervals. Full stringsets must include the first four preferences at least once, and then any other notes that fit into the diagram. A position indicator shows where each chord starts on the neck. Chord construction is based on the comfortable reach of 4 frets.
  </p>
  <p>
    Each diagram can be selectively toggled on and off for printing by clicking the print icon above each block. Each note in a block can also be toggled on and off for printing by clicking the note. This is especially useful for wide chords.
  </p>
  <p>
    These stringset diagrams can be printed to compile a thorough chord library for any key.
  </p>
</div>

<div class="fretboard" *ngIf="showFretboard">
  <!-- draw position markers -->
  <div class="markers">
    <div class="markerSpacer"></div>
    <div class="markerSpacer"></div>
    <div class="markerNut"></div>
    <div class="markerBlock" *ngFor="let m of data.fretboardMarkerArray">
      {{m}}
    </div>
    <div class="markerSpacer"></div>
  </div>

  <!-- draw the strings -->
  <div class="strings">
    <div class="string"
          *ngFor="let strClass of data.currentInstrument.stringImageClasses; let strIdx = index;">
      <div class="tuningBlock">{{data.currentInstrument.tuning[strIdx]}}</div>
      <div class="nutEnd">
        <img [ngClass]="data.currentInstrument.stringImageClasses[strIdx]"
              *ngIf="!data.fretboardNoteArray[strIdx][0].class.includes('banjo')"
              [src]="'../../assets/images/' + data.currentInstrument.stringImageClasses[strIdx] + '.png'" />
      </div>
      <div class="nut" [ngClass]="data.fretboardNoteArray[strIdx][0].class">
        <div class="note nutNote"
            *ngIf="data.fretboardNoteArray[strIdx][0].visible"
            [ngClass]="data.fretboardNoteArray[strIdx][0].class"
            (click)="toggleNote($event)">
          {{data.fretboardNoteArray[strIdx][0].display}}
        </div>
      </div>
      <div class="fretBlock"
             [ngClass]="data.fretboardNoteArray[strIdx][fretIdx + 1].class"
             *ngFor="let fb of data.fretboardMarkerArray; let fretIdx = index;">
        <img [ngClass]="data.currentInstrument.stringImageClasses[strIdx]"
              *ngIf="!data.fretboardNoteArray[strIdx][fretIdx + 1].class.includes('banjo')"
							[src]="'../assets/images/' + data.currentInstrument.stringImageClasses[strIdx] + '.png'" />
        <div class="note"
            *ngIf="data.fretboardNoteArray[strIdx][fretIdx + 1].visible"
            [ngClass]="data.fretboardNoteArray[strIdx][fretIdx + 1].class"
            (click)="toggleNote($event)">
            {{data.fretboardNoteArray[strIdx][fretIdx + 1].display}}
        </div>
      </div>
      <div class="bridgeEnd"></div>
    </div>
  </div>

  <!-- add bottom spacer -->
  <div class="markers">
    <div class="markerSpacer"></div>
    <div class="markerSpacer"></div>
    <div class="markerNut" [ngClass]="{'banjo': data.currentInstrument.banjo}"></div>
    <div class="markerBlock" *ngFor="let m of data.fretboardMarkerArray; let idx = index"
          [ngClass]="{'banjo': data.currentInstrument.banjo && idx < 5}"></div>
    <div class="markerSpacer"></div>
  </div>
</div>

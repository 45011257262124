<div class="printStringsetsContainer">
  <h1 class="mat-text-2">Chord Stringset Diagrams</h1>
  <app-print-controls-bar (printChords)="onPrintChords($event)"></app-print-controls-bar>
  <app-chord-detail-bar></app-chord-detail-bar>
  <app-print-chords-help *ngIf="data.showHelp"></app-print-chords-help>

  <div class="stringsetContainer">

  <!-- stringset -->

      <div class="stringset" *ngFor="let ss of stringsetArray">
        <h1 class="mat-text-2">
          {{data.currentKey.key}}{{data.currentChord.abbreviation}}: {{ss.title}}
        </h1>

  <!-- diagrams -->

        <div class="diagramBlockSection" *ngFor="let dgm of ss.diagrams; let diagramIdx = index">
          <div class="stringsetDiagram">
            <button class="diagramPrintButton" matTooltip="Toggle chords on/off..."
                    mat-icon-button (click)="dgm.show = !dgm.show" aria-label="toggle chord on/off">
              <i class="material-icons" [ngStyle]="{'color': dgm.show ? '' : 'rgba(0, 0, 0, 0.25)'}">print</i>
            </button>
            <div class="positionLabel">
              {{dgm.position === 0 ? ' ' : dgm.position}}
            </div>
            <div class="string" *ngFor="let s of dgm.strings; let strIdx = index;">
              <div class="nutSpace" *ngIf="dgm.position > 0">
                <div class="stringLine"></div>
                <div class="note" matTooltip="Toggle notes on/off..."
                      (click)="s[0] = toggleNote($event, s[0])"
                      *ngIf="s[0].substring(0, 1) === 'S' || s[0].substring(0, 1) === 'D'">
                  {{s[0].slice(2)}}
                </div>
              </div>
              <div class="nut" *ngIf="dgm.position === 0">
                <div class="stringLine"></div>
                <div class="note noteNut" matTooltip="Toggle notes on/off..."
                        (click)="s[0] = toggleNote($event, s[0])"
                        *ngIf="s[0].substring(0, 1) === 'S' || s[0].substring(0, 1) === 'D'">
                  {{s[0].slice(2)}}
                </div>
              </div>
              <div class="diagramBlockContainer" *ngFor="let note of s; let noteIdx = index;">
                <div class="diagramBlock" *ngIf="noteIdx > 0">
                  <div class="stringLine"></div>
                  <div class="note" matTooltip="Toggle notes on/off..."
                    (click)="s[noteIdx] = toggleNote($event, s[noteIdx])"
                    *ngIf="s[noteIdx].substring(0, 1) === 'S' || s[noteIdx].substring(0, 1) === 'D'">
                    {{note.slice(2)}}
                  </div>
                </div>
              </div>
              <div class="diagramEnd">
                <div class="stringLine"></div>
              </div>
            </div>
          </div>
        </div>

  <!-- END diagram -->

      </div>

  <!-- END stringset -->

    <!-- </ng-scrollbar> -->
  </div>

</div>

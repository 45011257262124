<!-- help for chord tool page -->
<div class="help helpPanel" *ngIf="data.showHelp">
  <!-- close -->
  <button mat-icon-button class="closeButton"
        matTooltip="close..." (click)="data.showHelp = false; data.componentFlag = 'show'">
    <i class="material-icons">close</i>
  </button>

  <h1 class="mat-text-2">Scale Tool Help...</h1>
  <p>
    The Scale Tool will show all the notes of any selected scale. You will see either actual note names or the intervals of the scale.
  </p>
  <p>
    Choose an instrument to set the tunings for each string.
  <p>
    Choose a key from the key list and then choose a scale from the scale list: the scale list is loosely grouped by COMMON, MODES, BLUES, ETHNIC, THEORY BASED, ARCANE JAZZ SCALES, and BEBOP SCALES.
  </p>
  <p>
    Use the toggle to switch between note names or intervals.
  </p>
  <p>
    You can play the scale using the audio controls.
  </p>
  <p>
    The 'Print' button displays a page where you can selectively turn off notes to build a library of various long scales.
  </p>
</div>

<div #uwrcToolsContainer class="contentContainer uwrcToolsContainer textColor">
	<h1 class="mat-display-1">UW Richland Performance Aids</h1>

	<p class="subtitle">Here are various tools to help prepare for performances: practice backing tracks, score notes, outside links to interpretation, etc.</p>

  <ng-scrollbar #performanceAidsListSB class="performanceAidsScrollbarContainer">
    <div class="perfomanceAidsContainer">

      <!-- WINTER 2019 BACKING TRACKS -->
      <p class="performanceAidsContainerTitle">Winter 2019 Concert - Band</p>
      <ul class="performanceAidsList">
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece1: detail1</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece1: detail2</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece1: detail3</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece2: detail1</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece2: detail2</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece2: detail3</a></li>
      </ul>

      <p class="performanceAidsContainerTitle">Winter 2019 Concert - Choir</p>
      <ul class="performanceAidsList">
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece1: detail1</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece1: detail2</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece1: detail3</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece2: detail1</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece2: detail2</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece2: detail3</a></li>
      </ul>

      <!-- SPRING 2020 BACKING TRACKS -->
      <p class="performanceAidsContainerTitle">Spring 2020 Concert - Band</p>
      <ul class="performanceAidsList">
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece1: detail1</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece1: detail2</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece1: detail3</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece2: detail1</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece2: detail2</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece2: detail3</a></li>
      </ul>

      <p class="performanceAidsContainerTitle">Spring 2020 Concert - Choir</p>
      <ul class="performanceAidsList">
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece1: detail1</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece1: detail2</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece1: detail3</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece2: detail1</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece2: detail2</a></li>
        <li><a class="textColor" download href="../../assets/uwrc/Endecha.mp3">Composer - Piece2: detail3</a></li>
      </ul>
    </div>
  </ng-scrollbar>
</div>

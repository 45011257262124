import { Component, NgZone, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { GlobalDataService } from '../../providers/global-data.service';
import { NgScrollbar } from 'ngx-scrollbar';

import {cloneDeep} from 'lodash';
const copyObj = cloneDeep;

@Component({
  selector: 'app-print-chords',
  templateUrl: './print-chords.component.html',
  styleUrls: ['./print-chords.component.styl']
})
export class PrintChordsComponent implements OnInit, AfterViewInit {
  showDiagrams = true;
  stringsetArray = [];
  iterator = Array.from(Array(12).keys());
  // @ViewChild('scrollbar') scrollbarRef: NgScrollbar;

  constructor(public data: GlobalDataService, public zone: NgZone) { }

  ngOnInit() {
    window.onafterprint = () => {
      console.log('onafterprint() fires...');
      this.showDiagrams = true;
    };
    this.buildStringsetArrays();
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.showDiagrams = true;
    //   this.scrollbarRef.update();
    // }, 10);
  }

  onPrintChords(ev) {
    console.log('onPrintChords(): ', ev);
    this.showDiagrams = false;
    setTimeout(() => {
      window.print();
    }, 500);
  }

  toggleNote(ev, note) {
    if (ev.target.classList.contains('noteHide')) {
      ev.target.classList.remove('noteHide');
      note = 'S' + note.substring(1);
    } else {
      ev.target.classList.add('noteHide');
      note = 'D' + note.substring(1);
    }
    return note;
  }

  // -- build all possible stringsets and fill stringsetArray with object arrays for each stringset --
  buildStringsetArrays() {
    if (this.data.currentInstrument.stringCount === 3) {
      const stringset: any = {};
      const strings = [this.data.fretboardNoteArray[0], this.data.fretboardNoteArray[1], this.data.fretboardNoteArray[2]];
      stringset.diagrams = this.buildStringsetDiagrams(strings, 0, false);
      stringset.title = 'Stringset 321';
      this.stringsetArray.push(stringset);
    }
    if (this.data.currentInstrument.stringCount > 3 && this.data.currentInstrument.stringCount < 8) {
      const stringset: any = {};
      const strings = [this.data.fretboardNoteArray[0], this.data.fretboardNoteArray[1], this.data.fretboardNoteArray[2], this.data.fretboardNoteArray[3]];
      stringset.diagrams = this.buildStringsetDiagrams(strings, 0, false);
      stringset.title = 'Stringset 4321';
      this.stringsetArray.push(stringset);
    }
    if (this.data.currentInstrument.stringCount > 4 && this.data.currentInstrument.stringCount < 8) {
      const stringset: any = {};
      const strings = [this.data.fretboardNoteArray[1], this.data.fretboardNoteArray[2], this.data.fretboardNoteArray[3], this.data.fretboardNoteArray[4]];
      stringset.diagrams = this.buildStringsetDiagrams(strings, 0, false);
      stringset.title = 'Stringset 5432';
      this.stringsetArray.push(stringset);
    }
    if (this.data.currentInstrument.stringCount > 5 && this.data.currentInstrument.stringCount < 8) {
      const stringset: any = {};
      const strings = [this.data.fretboardNoteArray[2], this.data.fretboardNoteArray[3], this.data.fretboardNoteArray[4], this.data.fretboardNoteArray[5]];
      stringset.diagrams = this.buildStringsetDiagrams(strings, 0, false);
      stringset.title = 'Stringset 6543';
      this.stringsetArray.push(stringset);
    }
    if (this.data.currentInstrument.stringCount > 4 && this.data.currentInstrument.stringCount < 8) {
      const stringset: any = {};
      const strings = [this.data.fretboardNoteArray[0], this.data.fretboardNoteArray[1], this.data.fretboardNoteArray[2], this.data.fretboardNoteArray[4]];
      stringset.diagrams = this.buildStringsetDiagrams(strings, 1, false);
      stringset.title = 'Stringset 5321';
      this.stringsetArray.push(stringset);
    }
    if (this.data.currentInstrument.stringCount > 5 && this.data.currentInstrument.stringCount < 8) {
      const stringset: any = {};
      const strings = [this.data.fretboardNoteArray[1], this.data.fretboardNoteArray[2], this.data.fretboardNoteArray[3], this.data.fretboardNoteArray[5]];
      stringset.diagrams = this.buildStringsetDiagrams(strings, 1, false);
      stringset.title = 'Stringset 6432';
      this.stringsetArray.push(stringset);
    }
    if (this.data.currentInstrument.stringCount === 7) {
      const stringset: any = {};
      const strings = [this.data.fretboardNoteArray[1], this.data.fretboardNoteArray[2], this.data.fretboardNoteArray[3], this.data.fretboardNoteArray[6]];
      stringset.diagrams = this.buildStringsetDiagrams(strings, 2, false);
      stringset.title = 'Stringset 7432';
      this.stringsetArray.push(stringset);
    }
    if (this.data.currentInstrument.stringCount === 7) {
      const stringset: any = {};
      const strings = [this.data.fretboardNoteArray[2], this.data.fretboardNoteArray[3], this.data.fretboardNoteArray[4], this.data.fretboardNoteArray[6]];
      stringset.diagrams = this.buildStringsetDiagrams(strings, 1, false);
      stringset.title = 'Stringset 7543';
      this.stringsetArray.push(stringset);
    }
    if (this.data.currentInstrument.stringCount > 4) {
      const stringset: any = {};
      const strings = [];
      for ( const str of this.data.fretboardNoteArray) {
        strings.push(str);
      }
      stringset.diagrams = this.buildStringsetDiagrams(strings, 0, true);
      stringset.title = 'Wide Chords';
      this.stringsetArray.push(stringset);
    }
  }

  // -- iterate over string blocks, get a chord if possible --
  buildStringsetDiagrams(strings, blanks, wide) {
    let diagrams = [];

    // for (let idx = 0; idx < 3; idx++) {
    for (const idx of this.iterator) {
      const stringBlockArray = [];
      const reach = idx === 0 ? idx + this.data.reach + 1 : idx + this.data.reach;

      for (const str of strings) {
        stringBlockArray.push(str.slice(idx, reach));
      }
      const chords = this.buildChordDiagrams(stringBlockArray, idx, blanks, wide);
      diagrams = diagrams.concat(chords);
    }
    return diagrams;
  }

  // -- take string block and build array of all chords for that position --
  buildChordDiagrams(strings, position, blanks, wide) {
    const chords = [];
    const returnChords = [];
    const chordArray = {position, show: true, strings: []};
    const reach = strings[0].length;

    // -- test position for actual chord --

    let hasChord = true;
    if (!wide) {
      for (const str of strings) {
        const notes = [];
        for (const [idx, note] of str.entries()) {
          if (note.interval) {
            notes.push({index: idx, interval: note.interval});
          }
        }
        chordArray.strings.push(notes);
        if (notes.length === 0) {
          hasChord = false;
        }
      }
    } else {
      for (const str of strings) {
        const notes = [];
        for (const [idx, note] of str.entries()) {
          if (note.interval) {
            notes.push({index: idx, interval: note.interval});
          }
        }
        chordArray.strings.push(notes);
      }
    }

    if (hasChord) {
      for (const note of chordArray.strings[0]) {
        const obj = {position: chordArray.position, strings: []};
        obj.strings.push(new Array());
        obj.strings[0].push(note);
        chords.push(obj);
      }
      this.expandChordDiagrams(chordArray, chords, 0);
    }

    for (const chord of chords) {
      hasChord = false;
      let pref1 = 0;
      let pref2 = 0;
      let pref3 = 0;
      let pref4 = 0;

      for (const [idx, str] of chord.strings.entries()) {
        const strArray = Array(reach).fill('H-');
        if (str.length > 0) {
          strArray[str[0].index] = 'S-' + str[0].interval;
          if (str[0].interval === this.data.currentChord.preference[0]) {
            pref1++;
          }
          if (str[0].interval === this.data.currentChord.preference[1]) {
            pref2++;
          }
          if (str[0].interval === this.data.currentChord.preference[2]) {
            pref3++;
          }
          if (this.data.currentChord.preference.length > 3 &&
                  str[0].interval === this.data.currentChord.preference[3]) {
            pref4++;
          }
        }
        chord.strings[idx] = strArray;
      }
      if (blanks > 0) {
        for (let idx = 0; idx < blanks; idx++) {
          const strArray = Array(reach).fill('');
          chord.strings.splice(3, 0, strArray);
        }
      }
      if ( wide && pref1 > 0  && pref2 > 0 && pref3 > 0) {
        if (this.data.currentChord.preference.length > 3) {
          if (pref4 > 0) {
            hasChord = this.hasChordGotFirstNote(chord);
          }
        } else {
          hasChord = this.hasChordGotFirstNote(chord);
        }
      }
      if ( !wide && pref1 > 0  && pref2 > 0 && pref3 > 0) {
        hasChord = this.hasChordGotFirstNote(chord);
      }
      if (hasChord) {
        chord.show = true;
        returnChords.push(chord);
      }
    }
    return returnChords;
  }

  // -- expand the chord array to include all combinations of mutiple notes --
  expandChordDiagrams(chordArray, returnArray, strIdx) {
    const chordsLength = returnArray.length;
    strIdx++;

    for (let chordIdx = 0; chordIdx < chordsLength; chordIdx++) {
      const chord = returnArray[chordIdx];
      chord.strings.push(new Array());
      if (chordArray.strings[strIdx].length > 0) {
        for (const [idx, note] of chordArray.strings[strIdx].entries()) {
          if (idx === 0) {
            chord.strings[strIdx].push(note);
          }
          if (idx > 0) {
            const chordObj = copyObj(chord);
            chordObj.strings[strIdx] = [];
            chordObj.strings[strIdx].push(note);
            returnArray.push(chordObj);
          }
        }
      }
    }

    if (strIdx === chordArray.strings.length - 1) {
      return returnArray;
    } else {
      this.expandChordDiagrams(chordArray, returnArray, strIdx);
    }
  }

  hasChordGotFirstNote(chord) {
    let blankCount = 0;

    for (const str of chord.strings) {
      if (str[0].substring(0, 1) === 'H') {
        blankCount++;
      }
    }
    return blankCount < chord.strings.length ? true : false;
  }
}

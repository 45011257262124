import { Component } from '@angular/core';
import { GlobalDataService } from '../../../providers/global-data.service';

@Component({
  selector: 'app-show-chords-help',
  templateUrl: './show-chords-help.component.html',
  styleUrls: ['./show-chords-help.component.styl']
})
export class ShowChordsHelpComponent {

  constructor(public data: GlobalDataService) { }

}

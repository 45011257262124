import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '../providers/global-data.service';

import {cloneDeep} from 'lodash';
const copyObj = cloneDeep;

@Component({
  selector: 'app-scale-tool',
  templateUrl: './scale-tool.component.html',
  styleUrls: ['./scale-tool.component.styl']
})
export class ScaleToolComponent implements OnInit {
  componentFlag = 'show';
  isLoadingSamples = false;

  constructor(public data: GlobalDataService) { }

  ngOnInit() {
    this.data.componentFlag = 'show';
  }

  toggleComponentFlag(flag) {
    this.componentFlag = flag;
  }
  newScale(ev) {
    console.log('newScale(): ', ev);
  }
}

import { Component } from '@angular/core';
import { GlobalDataService } from '../../providers/global-data.service';

@Component({
  selector: 'app-print-scale',
  templateUrl: './print-scale.component.html',
  styleUrls: ['./print-scale.component.styl']
}) export class PrintScaleComponent {

  constructor(public data: GlobalDataService) { }

}

import { Component } from '@angular/core';
import { GlobalDataService } from '../../providers/global-data.service';

@Component({
  selector: 'app-print-arpeggios',
  templateUrl: './print-arpeggios.component.html',
  styleUrls: ['./print-arpeggios.component.styl']
})
export class PrintArpeggiosComponent {

  constructor(public data: GlobalDataService) { }

  printFretboard(ev) {
    window.print();
  }
}

import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '../../providers/global-data.service';

@Component({
  selector: 'app-print-controls-bar',
  templateUrl: './print-controls-bar.component.html',
  styleUrls: ['./print-controls-bar.component.styl']
})
export class PrintControlsBarComponent implements OnInit {

  constructor(public data: GlobalDataService) { }

  ngOnInit() {
  }

  printScale() {
    window.print();
  }
}

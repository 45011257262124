import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.styl']
})
export class LoginComponent implements OnInit {
  userLogin = '';
  password = '';
  showPassword = false;

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>
  ) {}

  ngOnInit() {
    setTimeout( () => {
      this.dialogRef.disableClose = true;
    });
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  login() {
    if (this.userLogin.toUpperCase() === 'UWRC' && this.password === 'uwrcmusic') {
      console.log('User logged in...');
      this.dialogRef.close({action: 'logged-in'});
    } else {
      console.log('User login failed...');
      this.userLogin = '-- UNAUTHORIZED --';
      this.password = '';
    }
  }

  logout() {
    console.log('User logged out...');
    this.dialogRef.close({action: 'logged-out'});
  }

  cancel() {
    console.log('User login cancelled...');
    this.dialogRef.close({action: 'cancel'});
  }
}

<div class="printArpeggiosContainer">
  <h1 class="mat-text-2">Chord Arpeggios</h1>
  <app-print-controls-bar (printChords)="printFretboard($event)"></app-print-controls-bar>
  <app-chord-detail-bar></app-chord-detail-bar>

  <app-fretboard></app-fretboard>

  <div class="centered noPrint">(for arpeggios: click on notes to hide/show from the final print...)</div>
  <app-print-arpeggios-help *ngIf="data.showHelp"></app-print-arpeggios-help>
</div>
